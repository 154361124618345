import React, { Component } from "react"
import "./style.scss"
import Button from "react-bootstrap/Button"
import axios from "axios"
// import { cl } from "@services/consolelogger"

class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      emailAddress: "",
      buttonDisabled: true,
      sendSuccess: false,
      waitingForResponse: false,
      error: "",
    }
  }

  emailChangeHandler(ev) {
    let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      buttonDisabled = !emailRegex.test(ev.target.value.toLowerCase()),
      emailAddress = ev.target.value
    this.setState({ buttonDisabled, emailAddress })
  }

  submitHandler(ev) {
    ev.preventDefault()
    this.setState({ buttonDisabled: true, waitingForResponse: true })
    let data = { email: this.state.emailAddress }
    let options = {
      method: "POST",
      data: JSON.stringify(data),
      url: `${process.env.API_URL}/password-reset`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
    axios(options)
      .then((res) => {
        this.setState({ sendSuccess: true, waitingForResponse: false })
      })
      .catch((err) => {
        this.setState({
          buttonDisabled: false,
          waitingForResponse: false,
          error: true,
        })
      })
  }

  render() {
    let { buttonDisabled, waitingForResponse, sendSuccess, error } = this.state
    return (
      <div className="forgot-password-form">
        {!sendSuccess ? (
          <form onSubmit={this.submitHandler.bind(this)} noValidate>
            <label htmlFor="email">Email Address</label>
            <input
              id="email"
              name="email"
              onChange={this.emailChangeHandler.bind(this)}
              type="email"
              placeholder="example@yourdomain.com"
            />
            <Button disabled={buttonDisabled} type="submit">
              {waitingForResponse ? `Sending` : `Send me a reset email`}
            </Button>
            {error && (
              <p className="error-text">
                There was an error, please try again or contact us at{" "}
                <a href="mailto:support@bayparksw.com">support@bayparksw.com</a>
              </p>
            )}
          </form>
        ) : (
          <p className="sent">Please check your email inbox for a reset link</p>
        )}
      </div>
    )
  }
}

export default ForgotPasswordForm
