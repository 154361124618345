import React from "react"
import Layout from "@components/layout"
import ForgotPasswordForm from "@components/_molecules/form_forgot_password"
// import Seo from "@components/seo"

const ForgotPassword = () => (
  <Layout>
    {/*<Seo title="Home" />*/}
    <main>
      <div className="section cc-store-home-wrap">
        <div className="container">
          <h1>Forgot Password</h1>
          <p style={{ maxWidth: `600px`, margin: `2rem auto 1rem` }}>
            Please enter your email address, you will be sent a password reset
            email link to your inbox
          </p>
          <ForgotPasswordForm />
        </div>
      </div>
    </main>
  </Layout>
)

export default ForgotPassword
